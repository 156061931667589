import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { CardFullMobileModal, useModalState } from "@jasperlabs/jux-next";

import { useCaptureGraphQLError } from "../../../../api";
import { toast } from "../../../utilities/toast/toast";

import AccountDetailsForm from "../AccountDetailsForm";

const GENERIC_ERROR_TOAST = "generic-error-toast";

const isDescendantOfElementById = (descendent, id) =>
	Boolean(document.getElementById(id)?.contains(descendent));

const AccountDetailsModal = ({ renderDisclosure, modalState }) => {
	const captureMutationError = useCaptureGraphQLError();
	const defaultModalState = useModalState();
	const modal = modalState ?? defaultModalState;

	const handleError = useCallback(
		(error) => {
			captureMutationError(error);
			toast.errorGeneric({
				toastId: GENERIC_ERROR_TOAST,
			});
		},
		[captureMutationError],
	);

	return (
		<CardFullMobileModal
			modalState={modal}
			maxWidth="fit-content"
			ariaLabel="Provide account details modal"
			disclosure={renderDisclosure()}
			hideOnClickOutside={false}
			onClickOutside={(e) => {
				if (isDescendantOfElementById(e.target, GENERIC_ERROR_TOAST)) {
					return;
				}
				modal.closeModal();
			}}
		>
			<div
				className="mb-12 grid w-full gap-6 md:mb-0"
				// Add space on mobile so the user can scroll submit into view
			>
				<div className="grid gap-5 text-center">
					<h2 className="text-heading-sm">Provide account details</h2>
					<p className="text-body-default text-color-body">
						We just need a few bits of basic information to finish setting up
						your account. Complete the below to get access to the platform.
					</p>
				</div>
				<AccountDetailsForm
					onSuccess={modal.closeModal}
					onError={handleError}
				/>
			</div>
		</CardFullMobileModal>
	);
};

AccountDetailsModal.defaultProps = {
	modalState: null,
};

AccountDetailsModal.propTypes = {
	modalState: PropTypes.shape({ hide: PropTypes.func }),
	renderDisclosure: PropTypes.func.isRequired,
};

export default AccountDetailsModal;
