import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as R from "ramda";

import {
	Button,
	ButtonSize,
	IconLock,
	InputField,
	PasswordInputField,
} from "@jasperlabs/jux-next";

import {
	mutations,
	queries,
	useMutationOnError,
	useMutationOnSuccess,
} from "../../../../api";

import { useSession } from "../../../../contexts/session";

import useAutofocus from "../../../utilities/useAutofocus";

const parseLoginAttemptError = R.compose(
	R.any(R.propEq("message", "LoginAttemptFailed")),
	R.propOr([], "graphQLErrors"),
);

const LoginForm = ({
	onLoginAttemptFailed,
	onError,
	onSuccess,
	presetEmail,
}) => {
	const { createSession: createSessionContext } = useSession();

	const focusInputRef = useAutofocus();
	const emailInputRef = presetEmail ? null : focusInputRef;
	const passwordInputRef = presetEmail ? focusInputRef : null;

	const { errors, handleBlur, handleChange, isValid, touched, values } =
		useFormik({
			isInitialValid: false,
			initialValues: {
				email: presetEmail || "",
				password: "",
			},
			validationSchema: Yup.object({
				email: Yup.string()
					.email("Enter a valid email")
					.required("Email is required"),
				password: Yup.string().required("Password is required"),
			}),
		});

	const [mutate, { loading: isLoading }] = useMutationOnSuccess(
		(data) => {
			const { createSession = {} } = data;
			const { session = {} } = createSession;
			createSessionContext(session);
			onSuccess(data);
		},
		useMutationOnError(
			(error) => {
				if (parseLoginAttemptError(error)) {
					onLoginAttemptFailed(error);
				} else {
					onError(error);
				}
			},
			/*
			 * The feature flags for the user are returned in the session. We need to ensure that the features are written to the root features query,
			 * so a newly logged in user gets a feature set specific to that user.
			 */
			useMutation(mutations.CREATE_SESSION, {
				update(cache, { data: { createSession: { features = [] } = {} } }) {
					cache.writeQuery({
						query: queries.FEATURES,
						data: {
							features,
						},
					});
				},
			}),
		),
	);

	const handleSubmit = (event) => {
		event.preventDefault();

		if (isValid && !isLoading) {
			mutate({
				variables: {
					email: values.email,
					password: values.password,
				},
			});
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="mb-3">
				<div className="grid grid-cols-12">
					{presetEmail ? null : (
						<div className="col-span-12">
							<InputField
								className=""
								id="email"
								label="Email"
								name="email"
								type="email"
								ref={emailInputRef}
								value={values.email}
								error={errors.email}
								hasError={touched.email && errors.email}
								disabled={isLoading}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</div>
					)}
					<div className="col-span-12">
						<PasswordInputField
							label="Password"
							id="password"
							name="password"
							type="password"
							ref={passwordInputRef}
							value={values.password}
							error={errors.password}
							hasError={touched.password && errors.password}
							disabled={isLoading}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-12">
				<div className="col-span-12">
					<Button
						isLoading={isLoading}
						isDisabled={!isValid}
						buttonSize={ButtonSize.Medium}
						iconLeft={<IconLock />}
						type="submit"
						aria-label="Login"
						className="w-full"
					>
						Login
					</Button>
				</div>
			</div>
		</form>
	);
};

LoginForm.defaultProps = {
	presetEmail: null,
	onLoginAttemptFailed() {},
	onSuccess() {},
	onError() {},
};

LoginForm.propTypes = {
	presetEmail: PropTypes.string,
	onLoginAttemptFailed: PropTypes.func,
	onSuccess: PropTypes.func,
	onError: PropTypes.func,
};

export default LoginForm;
