import React, { forwardRef } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

import { useModalState } from "@jasperlabs/jux-next";

import { useHasProvidedAccountDetails } from "components/utilities/authentication/WithHasProvidedAccountDetails";

import AccountDetailsModal from "./AccountDetailsModal";

const withClickIfHasProvidedAccountDetails = (C) => {
	const HoC = forwardRef((props, ref) => {
		const hasProvidedAccountDetails = useHasProvidedAccountDetails();
		const modalState = useModalState();

		if (hasProvidedAccountDetails && !modalState.visible) {
			return <C ref={ref} {...props} />;
		}

		// eslint-disable-next-line no-unused-vars
		const { as, ...rest } = props;

		return (
			<AccountDetailsModal
				modalState={modalState}
				renderDisclosure={(disclosureProps) => (
					<C ref={ref} type="button" {...rest} {...disclosureProps} />
				)}
			/>
		);
	});

	HoC.displayName = `withClickIfHasProvidedAccountDetails(${
		C.displayName || C.name
	})`;

	hoistNonReactStatics(HoC, C);

	return HoC;
};

export default withClickIfHasProvidedAccountDetails;
