/* eslint-disable */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CallingCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CallingCodesQuery = { __typename: 'Query', countries: Array<{ __typename: 'Country', id: string, name: string, callingCode: string } | null> };


export const CallingCodesDocument = gql`
    query CallingCodes {
  countries {
    id
    name
    callingCode
  }
}
    `;

/**
 * __useCallingCodesQuery__
 *
 * To run a query within a React component, call `useCallingCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallingCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallingCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCallingCodesQuery(baseOptions?: Apollo.QueryHookOptions<CallingCodesQuery, CallingCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallingCodesQuery, CallingCodesQueryVariables>(CallingCodesDocument, options);
      }
export function useCallingCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallingCodesQuery, CallingCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallingCodesQuery, CallingCodesQueryVariables>(CallingCodesDocument, options);
        }
export type CallingCodesQueryHookResult = ReturnType<typeof useCallingCodesQuery>;
export type CallingCodesLazyQueryHookResult = ReturnType<typeof useCallingCodesLazyQuery>;
export type CallingCodesQueryResult = Apollo.QueryResult<CallingCodesQuery, CallingCodesQueryVariables>;