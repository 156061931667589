import React from "react";

const SvgLock = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			d="M30.997 13.967H28.64v-2.44C28.657 6.83 24.827 3 20.113 3c-4.698 0-8.528 3.83-8.528 8.528v2.439H9.228c-.671 0-1.228.54-1.228 1.227v18.578C8 34.444 8.557 35 9.228 35h21.77c.67 0 1.227-.556 1.227-1.228V15.194c0-.687-.54-1.227-1.228-1.227zm-16.957-2.44c0-3.355 2.733-6.072 6.073-6.072 3.355 0 6.088 2.734 6.088 6.073v2.439H14.04v-2.44zm15.73 21.018H10.455V16.422h19.331v16.123h-.016z"
			fill="currentColor"
		/>
		<path
			d="M20.112 21.772a1.918 1.918 0 0 0-1.915 1.915c0 .77.458 1.408 1.096 1.72v.948c0 .459.36.819.819.819a.81.81 0 0 0 .818-.819v-.933a1.902 1.902 0 0 0 1.097-1.718 1.91 1.91 0 0 0-1.915-1.932z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgLock;
