import { useCallback, useRef } from "react";

import { useIsomorphicLayoutEffect } from "usehooks-ts";

function useEventCallback(fn) {
	const ref = useRef(fn);
	// we copy a ref to the callback scoped to the current state/props on each render
	useIsomorphicLayoutEffect(() => {
		ref.current = fn;
	});
	return useCallback((...args) => ref.current(...args), []);
}

const usePatchValues = ({ setValues, values, ...rest }) => ({
	patchValues: useEventCallback((patch) => setValues({ ...values, ...patch })),
	values,
	setValues,
	...rest,
});

export default usePatchValues;
