import React from "react";
import clsx from "clsx";
import { Switch } from "@headlessui/react";

import { InferProps } from "../../../types/util";
import { IconTick } from "../../icon";

export type CheckboxProps = Omit<
	InferProps<typeof Switch<"button">>,
	"checked" | "disabled" | "role" | "onChange"
> & {
	label?: React.ReactNode;
	checked?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	onChange?: (checked: boolean) => void;
	error?: React.ReactNode;
	hasInlineError?: boolean;
	isLoading?: boolean;
};

export const Checkbox = ({
	label,
	onChange,
	checked,
	disabled = false,
	hasError = false,
	error,
	className,
	hasInlineError = true,
	isLoading = false,
	...props
}: CheckboxProps) => {
	const isDisabled = disabled || isLoading;

	return (
		<Switch.Group>
			<div className={clsx("flex flex-col gap-1", className)}>
				<div
					className={clsx(
						"group flex gap-3",
						isLoading && ["cursor-progress [&>*]:!cursor-progress"],
					)}
				>
					<Switch
						type="button"
						checked={checked}
						onChange={onChange}
						disabled={isDisabled}
						className={clsx(
							"mt-[2px] h-5 w-5",
							"flex flex-shrink-0 items-center justify-center border-solid",
							"form-field focus:outline-primary-500",
							hasError && "form-field-error border-2",
							!isDisabled && [
								"ui-checked:border-transparent",
								hasError
									? "ui-checked:bg-form-field-error-checked"
									: "ui-checked:bg-form-field-checked",
								isLoading && "cursor-progress",
							],
						)}
						{...props}
					>
						<IconTick
							className={clsx(
								"h-3 w-3 invisible ui-checked:visible",
								isDisabled ? "text-form-field-icon-disabled" : "text-white",
							)}
						/>
					</Switch>
					{label && (
						<Switch.Label
							as="div"
							className={clsx(
								"text-body-default md:text-body-sm text-left leading-normal",
								isDisabled ? "cursor-not-allowed" : "cursor-pointer",
							)}
						>
							{label}
						</Switch.Label>
					)}
				</div>
				{hasInlineError && (
					<p className="text-label-default md:text-label-sm text-label-default text-form-field-error text-left !font-semibold leading-tight">
						{(hasError && error) || <>&nbsp;</>}
					</p>
				)}
			</div>
		</Switch.Group>
	);
};
