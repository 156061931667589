import React, { Fragment } from "react";

import { useActiveTenant } from "tenants/TenantProvider";

const TenantName = () => {
	const tenant = useActiveTenant();

	return <Fragment>{tenant.name}</Fragment>;
};

export default TenantName;
