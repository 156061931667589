import React, { useEffect } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { InputField } from "@jasperlabs/jux-next";

import useAutofocus from "../../utilities/useAutofocus";

import { MAX_INPUT_CHARACTERS } from "../../../constants/validation";

const schema = Yup.object({
	firstName: Yup.string()
		.required("First name is required")
		.max(
			MAX_INPUT_CHARACTERS,
			`First name must be less than ${MAX_INPUT_CHARACTERS} characters`,
		),
	middleName: Yup.string().max(
		MAX_INPUT_CHARACTERS,
		`Middle name(s) must be less than ${MAX_INPUT_CHARACTERS} characters`,
	),
	lastName: Yup.string()
		.required("Last name is required")
		.max(
			MAX_INPUT_CHARACTERS,
			`Last name must be less than ${MAX_INPUT_CHARACTERS} characters`,
		),
});

const LegalNamesMiniForm = ({
	isDisabled,
	onValues,
	hasAutofocus,
	defaultValues,
}) => {
	const inputRef = useAutofocus();

	const { errors, handleChange, handleBlur, isValid, touched, values } =
		useFormik({
			validateOnMount: true,
			validationSchema: schema,
			initialValues: {
				firstName: defaultValues.firstName || "",
				middleName: defaultValues.middleName || "",
				lastName: defaultValues.lastName || "",
			},
		});

	useEffect(() => {
		if (isValid) {
			onValues({
				firstName: values.firstName,
				middleName: values.middleName,
				lastName: values.lastName,
			});
		} else {
			onValues({ firstName: null, middleName: null, lastName: null });
		}
	}, [isValid, onValues, values]);

	return (
		<div className="mb-2 grid grid-cols-1 gap-4 md:grid-cols-2">
			<InputField
				ref={hasAutofocus ? inputRef : null}
				id="firstName"
				name="firstName"
				label="Legal first name"
				value={values.firstName}
				error={errors.firstName}
				hasError={touched.firstName && errors.firstName}
				disabled={isDisabled}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			<InputField
				ref={hasAutofocus ? inputRef : null}
				id="middleName"
				name="middleName"
				label="Legal middle name(s)"
				hint="If middle name is applicable"
				value={values.middleName}
				error={errors.middleName}
				hasError={touched.middleName && errors.middleName}
				disabled={isDisabled}
				onChange={handleChange}
				onBlur={handleBlur}
			/>

			<div className="col-span-full">
				<InputField
					id="lastName"
					name="lastName"
					label="Legal last name"
					value={values.lastName}
					error={errors.lastName}
					hasError={touched.lastName && errors.lastName}
					disabled={isDisabled}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
			</div>
		</div>
	);
};

LegalNamesMiniForm.defaultProps = {
	hasAutofocus: false,
	defaultValues: {},
};

LegalNamesMiniForm.propTypes = {
	hasAutofocus: PropTypes.bool,
	isDisabled: PropTypes.bool.isRequired,
	onValues: PropTypes.func.isRequired,
	defaultValues: PropTypes.shape({
		firstName: PropTypes.string,
		middleName: PropTypes.string,
		lastName: PropTypes.string,
	}),
};

export default LegalNamesMiniForm;
