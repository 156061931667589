import React from "react";

const SvgTick = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 14 11" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.683 7.835L11.671.977l1.657 1.446-7.456 8.542L.764 6.368l1.471-1.636 3.448 3.103z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgTick;
