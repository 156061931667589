import React from "react";

const SvgShown = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<g clipPath="url(#Shown_svg__clip0)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.673 19.53S9.277 6.375 20.501 6.375 39.328 19.53 39.328 19.53s-7.603 13.095-18.827 13.095C9.277 32.625 1.673 19.53 1.673 19.53zm9.595 0c0 5.07 4.164 9.233 9.233 9.233 5.069 0 9.232-4.164 9.232-9.233 0-5.069-4.163-9.233-9.232-9.233-5.07 0-9.233 4.164-9.233 9.233zm5.13 0c0-2.233 1.81-4.164 4.103-4.164 2.233 0 4.164 1.931 4.164 4.164 0 2.293-1.931 4.104-4.164 4.104a4.07 4.07 0 0 1-4.104-4.104z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export default SvgShown;
