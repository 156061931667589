export const analyticsEvents = {
	CREATE_ACCOUNT_EVENT: {
		event: "Account Created",
		properties: {
			category: "Account Setup",
			label: "Create Account",
		},
	},
	COMPLETE_ACCOUNT_DETAILS_EVENT: {
		event: "Step Completed - Account Setup",
		properties: {
			category: "Account Setup",
			label: "Account Details",
		},
	},
};
