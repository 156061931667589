/* eslint-disable no-param-reassign */
import Analytics from "analytics";

// @ts-expect-error - no types for this package
import segmentPlugin from "@analytics/segment";

type TenantIdPluginConfig = { tenantId: string | null };

export const analytics = Analytics({
	app: "caruso-investor-portal",
	plugins: [
		{
			name: "add-tenant-id",
			config: { tenantId: null },
			initialize: async ({ config }: { config: TenantIdPluginConfig }) => {
				if (typeof window === "undefined") {
					return;
				}

				const response = await fetch("/api/lookup-tenant", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				});

				const { tenantId } = await response.json();

				config.tenantId = tenantId;
			},
			identify: ({
				payload,
				config,
			}: {
				config: TenantIdPluginConfig;
				payload: { traits: { tenantId?: string | null } };
			}) => {
				payload.traits.tenantId = config.tenantId;
			},
			track: ({
				payload,
				config,
			}: {
				config: TenantIdPluginConfig;
				payload: { properties: { tenantId?: string | null } };
			}) => {
				payload.properties.tenantId = config.tenantId;
			},
			page: ({
				payload,
				config,
			}: {
				config: TenantIdPluginConfig;
				payload: { properties: { tenantId?: string | null } };
			}) => {
				payload.properties.tenantId = config.tenantId;
			},
		},
		segmentPlugin({
			writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
		}),
	],
});
