/* eslint-disable */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckHasProvidedAccountDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckHasProvidedAccountDetailsQuery = { __typename: 'Query', account?: { __typename: 'Account', hasProvidedAccountDetails: boolean } | null };


export const CheckHasProvidedAccountDetailsDocument = gql`
    query CheckHasProvidedAccountDetails {
  account {
    hasProvidedAccountDetails
  }
}
    `;

/**
 * __useCheckHasProvidedAccountDetailsQuery__
 *
 * To run a query within a React component, call `useCheckHasProvidedAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckHasProvidedAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckHasProvidedAccountDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckHasProvidedAccountDetailsQuery(baseOptions?: Apollo.QueryHookOptions<CheckHasProvidedAccountDetailsQuery, CheckHasProvidedAccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckHasProvidedAccountDetailsQuery, CheckHasProvidedAccountDetailsQueryVariables>(CheckHasProvidedAccountDetailsDocument, options);
      }
export function useCheckHasProvidedAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckHasProvidedAccountDetailsQuery, CheckHasProvidedAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckHasProvidedAccountDetailsQuery, CheckHasProvidedAccountDetailsQueryVariables>(CheckHasProvidedAccountDetailsDocument, options);
        }
export type CheckHasProvidedAccountDetailsQueryHookResult = ReturnType<typeof useCheckHasProvidedAccountDetailsQuery>;
export type CheckHasProvidedAccountDetailsLazyQueryHookResult = ReturnType<typeof useCheckHasProvidedAccountDetailsLazyQuery>;
export type CheckHasProvidedAccountDetailsQueryResult = Apollo.QueryResult<CheckHasProvidedAccountDetailsQuery, CheckHasProvidedAccountDetailsQueryVariables>;