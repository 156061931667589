import React, { Fragment } from "react";

import {
	Modal,
	ModalHeaderPosition,
	ModalVariant,
	ModalInnerVariant,
	ModalProps,
} from "./Modal";

import { Hidden } from "../utils/Hidden";
import { Breakpoint } from "../utils";

export const CardFullMobileModal = (props: ModalProps) => (
	<Fragment>
		<Hidden from={Breakpoint.MD}>
			<Modal
				variant={ModalVariant.Fullscreen}
				innerVariant={ModalInnerVariant.Fullscreen}
				headerPosition={ModalHeaderPosition.Inside}
				{...props}
			/>
		</Hidden>
		<Hidden to={Breakpoint.MD}>
			<Modal
				variant={ModalVariant.Default}
				innerVariant={ModalInnerVariant.Card}
				headerPosition={ModalHeaderPosition.Inside}
				{...props}
			/>
		</Hidden>
	</Fragment>
);
