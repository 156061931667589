import React, { useState } from "react";
import clsx from "clsx";

import { IconHidden, IconShown } from "../../icon";
import { Input, InputProps } from "../Input";
import { FormField, FormFieldProps } from "../FormField";

export type PasswordInputProps = InputProps;

export const PasswordInput = ({ disabled, ...props }: PasswordInputProps) => {
	const [isVisible, setIsVisible] = useState(false);

	const handleVisibilityToggle = () => {
		setIsVisible(!isVisible);
	};

	return (
		<Input
			{...props}
			disabled={disabled}
			type={isVisible ? "text" : "password"}
			iconRight={
				isVisible ? (
					<IconShown
						aria-label="Hide password"
						className={clsx(
							"text-form-field-icon hover:text-form-field-icon-hover",
						)}
						onClick={handleVisibilityToggle}
					/>
				) : (
					<IconHidden
						aria-label="Show password"
						className={clsx(
							"text-form-field-icon hover:text-form-field-icon-hover",
						)}
						onClick={handleVisibilityToggle}
					/>
				)
			}
		/>
	);
};

export type PasswordInputFieldProps = PasswordInputProps & FormFieldProps;

export const PasswordInputField = ({
	id,
	label,
	error,
	hint,
	tooltip,
	hasError,
	disabled,
	...props
}: PasswordInputFieldProps) => (
	<FormField
		id={id}
		label={label}
		error={error}
		hint={hint}
		tooltip={tooltip}
		hasError={hasError}
		disabled={disabled}
	>
		<PasswordInput {...props} />
	</FormField>
);
