import React from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";

import {
	Button,
	Checkbox,
	IconLock,
	InputField,
	PasswordInputField,
} from "@jasperlabs/jux-next";
import { mutations, useMutationOnError, useMutationOnSuccess } from "api";

import { useSession } from "contexts/session";

import {
	MAX_INPUT_CHARACTERS,
	PASSWORD_MAXIMUM_LENGTH,
	PASSWORD_MINIMUM_LENGTH,
} from "constants/validation";
import { analyticsEvents } from "constants/analytics-events";

import {
	TenantPrivacyPolicyLink,
	TenantTermsAndConditionsLink,
} from "tenants/components";
import { analytics } from "lib/analytics";

import usePatchValues from "../../../utilities/usePatchValues";

import { LegalFirstAndLastNamesMiniForm } from "../../LegalNamesMiniForm";

const parseDoesEmailExist = R.compose(
	R.any(R.propEq("message", "ErrorEmailExists")),
	R.propOr([], "graphQLErrors"),
);

const validationSchema = Yup.object({
	firstName: Yup.string().required(),
	lastName: Yup.string().required(),
	email: Yup.string()
		.email("Enter a valid email")
		.required("Email is required")
		.max(
			MAX_INPUT_CHARACTERS,
			`Email must be less than ${MAX_INPUT_CHARACTERS} characters`,
		),
	password: Yup.string()
		.required("Password is required")
		.min(
			PASSWORD_MINIMUM_LENGTH,
			`Password must be at least ${PASSWORD_MINIMUM_LENGTH} characters long`,
		)
		.max(
			PASSWORD_MAXIMUM_LENGTH,
			`Password must be less than ${PASSWORD_MAXIMUM_LENGTH} characters`,
		)
		.matches(/\d/, "Password must contain a number")
		.matches(/[a-z]/, "Password must contain a lowercase character")
		.matches(/[A-Z]/, "Password must contain an uppercase character"),
	termsAgreed: Yup.boolean().test(
		"is-true",
		"Accept these terms to continue",
		Boolean,
	),
});

const CreateAccountForm = ({ defaultValues, onSuccess, onError }) => {
	const { createSession } = useSession();

	const {
		isValid,
		errors,
		handleBlur,
		handleChange,
		setErrors,
		touched,
		values,
		setFieldValue,
		patchValues,
	} = usePatchValues(
		useFormik({
			validationSchema,
			isInitialValid: false,
			initialValues: {
				firstName: defaultValues.firstName || "",
				lastName: defaultValues.lastName || "",
				email: defaultValues.email || "",
				password: "",
				termsAgreed: false,
			},
		}),
	);

	const [mutate, { loading: isLoading }] = useMutationOnSuccess(
		({ createAccount = {} }) => {
			const { session = {} } = createAccount;
			createSession(session);
			onSuccess();

			const { event, properties = {} } = analyticsEvents.CREATE_ACCOUNT_EVENT;
			analytics.track(event, properties);
		},
		useMutationOnError((error) => {
			if (parseDoesEmailExist(error)) {
				setErrors({
					email: "There is already an account using this email. Please login.",
				});
			} else {
				onError(error);
			}
		}, useMutation(mutations.CREATE_ACCOUNT)),
	);

	const handleSubmit = (event) => {
		event.preventDefault();

		if (isValid && !isLoading) {
			mutate({
				variables: {
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					password: values.password,
					termsAgreed: values.termsAgreed,
				},
			});
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="mb-3">
				<LegalFirstAndLastNamesMiniForm
					hasAutofocus
					defaultValues={defaultValues}
					onValues={patchValues}
					isDisabled={isLoading}
				/>
				<div className="grid grid-cols-12">
					<div className="col-span-12">
						<InputField
							id="email"
							label="Email"
							name="email"
							type="email"
							value={values.email}
							error={errors.email}
							hasError={touched.email && errors.email}
							disabled={isLoading}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</div>
					<div className="col-span-12">
						<PasswordInputField
							label="Password"
							id="password"
							name="password"
							type="password"
							value={values.password}
							error={errors.password}
							hasError={touched.password && errors.password}
							disabled={isLoading}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</div>
					<div className="col-span-12 mt-2">
						<Checkbox
							id="termsAgreed"
							name="termsAgreed"
							checked={values.termsAgreed}
							onBlur={handleBlur}
							hasError={touched.termsAgreed && errors.termsAgreed}
							error={errors.termsAgreed}
							onChange={(value) => setFieldValue("termsAgreed", value)}
							label={
								<span>
									By continuing I certify that I am 18 years of age, and I agree
									to the <TenantTermsAndConditionsLink /> and{" "}
									<TenantPrivacyPolicyLink />
								</span>
							}
						/>
					</div>
				</div>
			</div>
			<Button
				fullWidth
				isLoading={isLoading}
				isDisabled={!isValid}
				iconLeft={<IconLock />}
				type="submit"
				aria-label="Submit create account form"
			>
				Create account
			</Button>
		</form>
	);
};

CreateAccountForm.defaultProps = {
	defaultValues: {},
	onSuccess() {},
	onError() {},
};

CreateAccountForm.propTypes = {
	onError: PropTypes.func,
	onSuccess: PropTypes.func,
	defaultValues: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		email: PropTypes.string,
	}),
};

export default CreateAccountForm;
