import React from "react";

const SvgSlimCross = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20 21.518L7.518 34 6 32.482 18.482 20 6 7.518 7.518 6 20 18.482 32.482 6 34 7.518 21.518 20 34 32.482 32.482 34 20 21.518z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgSlimCross;
