import { useEffect, useRef } from "react";

const useAutofocus = () => {
	const ref = useRef({ focus() {} });

	useEffect(() => {
		ref.current.focus();
	}, []);

	return ref;
};

export default useAutofocus;
