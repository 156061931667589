import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";

import { InputField } from "@jasperlabs/jux-next";

import useAutofocus from "../../utilities/useAutofocus";

import { MAX_INPUT_CHARACTERS } from "../../../constants/validation";

const schema = Yup.object({
	firstName: Yup.string()
		.required("First name is required")
		.max(
			MAX_INPUT_CHARACTERS,
			`First name must be less than ${MAX_INPUT_CHARACTERS} characters`,
		),
	lastName: Yup.string()
		.required("Last name is required")
		.max(
			MAX_INPUT_CHARACTERS,
			`Last name must be less than ${MAX_INPUT_CHARACTERS} characters`,
		),
});

const LegalFirstAndLastNamesMiniForm = ({
	defaultValues,
	hasAutofocus,
	isDisabled,
	onValues,
}) => {
	const inputRef = useAutofocus();

	const { isValid, values, errors, touched, handleChange, handleBlur } =
		useFormik({
			validationSchema: schema,
			initialValues: {
				firstName: defaultValues.firstName || "",
				lastName: defaultValues.lastName || "",
			},
			isInitialValid: () => {
				try {
					schema.validateSync(defaultValues);
					return true;
				} catch {
					return false;
				}
			},
		});

	useEffect(() => {
		if (isValid) {
			onValues({
				firstName: values.firstName,
				lastName: values.lastName,
			});
		} else {
			onValues({ firstName: null, lastName: null });
		}
	}, [isValid, onValues, values]);

	return (
		<div className="mb-2 grid grid-cols-1 gap-4 md:grid-cols-2">
			<InputField
				ref={hasAutofocus ? inputRef : null}
				id="firstName"
				name="firstName"
				label="Legal first name"
				value={values.firstName}
				error={errors.firstName}
				hasError={touched.firstName && errors.firstName}
				disabled={isDisabled}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			<InputField
				id="lastName"
				name="lastName"
				label="Legal last name"
				value={values.lastName}
				error={errors.lastName}
				hasError={touched.lastName && errors.lastName}
				disabled={isDisabled}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		</div>
	);
};

LegalFirstAndLastNamesMiniForm.defaultProps = {
	hasAutofocus: false,
	defaultValues: {},
};

LegalFirstAndLastNamesMiniForm.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	onValues: PropTypes.func.isRequired,
	hasAutofocus: PropTypes.bool,
	defaultValues: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
	}),
};

export default LegalFirstAndLastNamesMiniForm;
