"use client";

import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { useSession } from "contexts/session";
import { useRouter, usePathname } from "next/navigation";

import { useCheckHasProvidedAccountDetailsQuery } from "./WithHasProvidedAccountDetails.generated";

type Props = {
	children: React.ReactNode;
};

export function useHasProvidedAccountDetails() {
	const { hasAuthenticatedSession } = useSession();

	const { data, loading } = useCheckHasProvidedAccountDetailsQuery({
		skip: !hasAuthenticatedSession,
	});
	/**
	 * By default assume details have been provided for
	 * the account, even if the field is not defined. also we want to assume this is true for unauthenticated users
	 */
	if (loading || !hasAuthenticatedSession) {
		return true;
	}

	return data?.account?.hasProvidedAccountDetails;
}

function WithHasProvidedAccountDetails({ children }: Props) {
	const [hasMounted, setHasMounted] = useState(false);
	const hasProvidedAccountDetails = useHasProvidedAccountDetails();
	const pathname = usePathname();
	const router = useRouter();

	useEffect(() => {
		setHasMounted(true);
	}, []);

	if (!hasMounted) {
		return null;
	}

	if (!hasProvidedAccountDetails && pathname !== "/account-setup") {
		router.replace("/account-setup");
		return null;
	}

	return <>{children}</>;
}

gql`
	query CheckHasProvidedAccountDetails {
		account {
			hasProvidedAccountDetails
		}
	}
`;

export default WithHasProvidedAccountDetails;
