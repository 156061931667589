import React from "react";
import PropTypes from "prop-types";

import { IconExit, Link, LinkVariant } from "@jasperlabs/jux-next";

import { useLogOut } from "../../../../contexts/logOut";
import { useSession } from "../../../../contexts/session";

const LogOutButton = ({ fallback }) => {
	const { logOut } = useLogOut();
	const { hasAuthenticatedSession } = useSession();

	if (!hasAuthenticatedSession) {
		return fallback;
	}

	return (
		<Link
			as="button"
			variant={LinkVariant.Neutral}
			onClick={logOut}
			className="text-body-default"
		>
			<div className="flex items-center gap-3 pr-2">
				<IconExit className="text-lg text-inherit" aria-hidden="true" />
				<div>Log out</div>
			</div>
		</Link>
	);
};

LogOutButton.defaultProps = {
	fallback: null,
};

LogOutButton.propTypes = {
	fallback: PropTypes.node,
};

export default LogOutButton;
