import React from "react";

const SvgHidden = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			d="M32.588 11.875l-3.778 4.079a8.981 8.981 0 0 1 .756 3.583c0 4.96-4.09 9.04-9.066 9.04-1.013 0-1.99-.202-2.911-.533l-2.893 3.124c1.787.735 3.74 1.175 5.804 1.175C31.537 32.362 39 19.538 39 19.538s-2.359-4.098-6.412-7.662zM20.482 23.56c.57 0 1.105-.129 1.603-.331a4.073 4.073 0 0 0 2.266-2.444c.13-.404.221-.808.221-1.25 0-.495-.11-.955-.276-1.396L35.462 6.105c.7-.753.645-1.91-.11-2.59A1.817 1.817 0 0 0 34.08 3c-.497 0-.995.202-1.345.588l-4.699 5.071c-2.248-1.212-4.79-2.02-7.536-2.02C9.481 6.638 2 19.536 2 19.536s2.93 5.034 7.831 8.783l-4.256 4.593c-.7.754-.645 1.911.11 2.59.056.038.11.056.148.093.166.128.331.22.516.294.184.055.387.11.57.11.498 0 .996-.202 1.346-.588l11.13-12.017c.35.092.7.166 1.087.166zm-3.796-2.646l-3.556 3.84a8.878 8.878 0 0 1-1.696-5.218c0-4.961 4.09-9.04 9.066-9.04 1.695 0 3.262.496 4.625 1.323l-3.556 3.84a3.67 3.67 0 0 0-1.106-.184c-2.248-.018-4.017 1.874-4.017 4.06 0 .479.092.938.24 1.379z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgHidden;
