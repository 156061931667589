/* eslint-disable */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteAccountDetailsMutationVariables = Types.Exact<{
  form: Types.AccountDetailsInput;
}>;


export type CompleteAccountDetailsMutation = { __typename: 'Mutation', completeAccountDetails?: { __typename: 'Account', id: string, hasProvidedAccountDetails: boolean } | null };


export const CompleteAccountDetailsDocument = gql`
    mutation CompleteAccountDetails($form: AccountDetailsInput!) {
  completeAccountDetails(form: $form) {
    id
    hasProvidedAccountDetails
  }
}
    `;
export type CompleteAccountDetailsMutationFn = Apollo.MutationFunction<CompleteAccountDetailsMutation, CompleteAccountDetailsMutationVariables>;

/**
 * __useCompleteAccountDetailsMutation__
 *
 * To run a mutation, you first call `useCompleteAccountDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAccountDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeAccountDetailsMutation, { data, loading, error }] = useCompleteAccountDetailsMutation({
 *   variables: {
 *      form: // value for 'form'
 *   },
 * });
 */
export function useCompleteAccountDetailsMutation(baseOptions?: Apollo.MutationHookOptions<CompleteAccountDetailsMutation, CompleteAccountDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteAccountDetailsMutation, CompleteAccountDetailsMutationVariables>(CompleteAccountDetailsDocument, options);
      }
export type CompleteAccountDetailsMutationHookResult = ReturnType<typeof useCompleteAccountDetailsMutation>;
export type CompleteAccountDetailsMutationResult = Apollo.MutationResult<CompleteAccountDetailsMutation>;
export type CompleteAccountDetailsMutationOptions = Apollo.BaseMutationOptions<CompleteAccountDetailsMutation, CompleteAccountDetailsMutationVariables>;