import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import RouterLink from "next/link";

import {
	CardFullMobileModal,
	useModalState,
	Link,
	LinkVariant,
} from "@jasperlabs/jux-next";

import TenantName from "tenants/components/TenantName";
import { useCaptureGraphQLError } from "../../../api";

import { toast } from "../../utilities/toast/toast";

import AccountDetailsForm from "./AccountDetailsForm";
import CreateAccountForm from "./CreateAccountForm";
import LoginForm from "./LoginForm";

const GENERIC_ERROR_TOAST = "generic-error-toast";
const LOGIN_ATTEMPT_FAILED_TOAST = "login-attempt-failed-toast";

const isDescendantOfElementById = (descendent, id) =>
	Boolean(document.getElementById(id)?.contains(descendent));

const authenticationModalVariants = {
	PROVIDE_ACCOUNT_DETAILS: "PROVIDE_ACCOUNT_DETAILS",
	CREATE_ACCOUNT: "CREATE_ACCOUNT",
	LOGIN: "LOGIN",
};

const DEFAULT_VARIANT = authenticationModalVariants.LOGIN;

const AuthenticationModal = ({
	onCreateAccountSuccess,
	renderDisclosure,
	onLoginSuccess,
	initialVariant,
	renderContent,
	modalState,
}) => {
	const [variant, setVariant] = useState(initialVariant);
	const captureMutationError = useCaptureGraphQLError();
	const defaultModalState = useModalState();

	const modal = modalState || defaultModalState;

	const handleLoginAttemptFailed = useCallback(() => {
		toast.warning("The email address or password provided is incorrect.", {
			toastId: LOGIN_ATTEMPT_FAILED_TOAST,
		});
	}, []);

	const handleGenericError = useCallback(
		(error) => {
			captureMutationError(error);
			toast.errorGeneric({ toastId: GENERIC_ERROR_TOAST });
		},
		[captureMutationError],
	);

	return (
		<CardFullMobileModal
			modalState={modal}
			hideOnClickOutside={false}
			ariaLabel="Authentication modal"
			onClose={() => {
				setVariant(initialVariant);
			}}
			disclosure={renderDisclosure({ modalVariant: variant })}
			onClickOutside={(e) => {
				if (
					isDescendantOfElementById(e.target, LOGIN_ATTEMPT_FAILED_TOAST) ||
					isDescendantOfElementById(e.target, GENERIC_ERROR_TOAST)
				) {
					return;
				}
				modal.closeModal();
			}}
		>
			<div
				data-testid="authentication-modal-content"
				className="flex w-full flex-col items-center justify-start"
			>
				{renderContent({ variant })}
				{variant === authenticationModalVariants.CREATE_ACCOUNT && (
					<div className="w-full max-w-full gap-7 md:max-w-[460px]">
						<CreateAccountForm
							onError={handleGenericError}
							onSuccess={() => {
								onCreateAccountSuccess();
								setVariant(authenticationModalVariants.PROVIDE_ACCOUNT_DETAILS);
							}}
						/>
						<div className="flex justify-center">
							<span className="text-neutral-400/80 text-subheading-sm pt-3	mb-12 md:mb-0">
								Already have an account?{" "}
								<Link
									as="button"
									variant={LinkVariant.Primary}
									onClick={() => {
										setVariant(authenticationModalVariants.LOGIN);
									}}
								>
									Login
								</Link>
							</span>
						</div>
					</div>
				)}
				{variant === authenticationModalVariants.PROVIDE_ACCOUNT_DETAILS && (
					<div
						className="mb-12 w-full max-w-full md:mb-0 md:max-w-[460px]"
						// Add space on mobile so the user can scroll submit into view
					>
						<AccountDetailsForm
							onError={handleGenericError}
							onSuccess={modal.closeModal}
						/>
					</div>
				)}
				{variant === authenticationModalVariants.LOGIN && (
					<div className="flex w-full max-w-full flex-col items-center justify-start md:max-w-[360px]">
						<div className="mb-7 w-full" width="100%">
							<LoginForm
								onLoginAttemptFailed={handleLoginAttemptFailed}
								onError={handleGenericError}
								onSuccess={() => {
									toast.dismiss();
									onLoginSuccess();
									modal.closeModal();
								}}
							/>
						</div>
						<Link
							variant={LinkVariant.Primary}
							as={RouterLink}
							href="/forgot-password"
							className="text-label-default mb-7 md:mb-8"
						>
							Forgot password?
						</Link>
						<span className="text-subheading-sm text-neutral-400/80">
							New to <TenantName />?{" "}
							<Link
								as="button"
								variant={LinkVariant.Primary}
								onClick={() => {
									setVariant(authenticationModalVariants.CREATE_ACCOUNT);
								}}
							>
								Create account
							</Link>
						</span>
					</div>
				)}
			</div>
		</CardFullMobileModal>
	);
};

AuthenticationModal.defaultProps = {
	initialVariant: DEFAULT_VARIANT,
	onCreateAccountSuccess() {},
	onLoginSuccess() {},
	renderContent() {},
	modalState: null,
};

AuthenticationModal.propTypes = {
	renderDisclosure: PropTypes.func.isRequired,
	modalState: PropTypes.shape({ hide: PropTypes.func }),
	onCreateAccountSuccess: PropTypes.func,
	onLoginSuccess: PropTypes.func,
	renderContent: PropTypes.func,
	initialVariant: PropTypes.oneOf(Object.keys(authenticationModalVariants)),
};

export default AuthenticationModal;
