import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, Checkbox } from "@jasperlabs/jux-next";
import AddressSearchForm, {
	addressSearchFormInitialValues,
	addressSearchFormSchema,
} from "components/organisms/AddressSearchForm";
import PhoneNumberForm, {
	phoneNumberInitialValues,
	phoneNumberSchema,
} from "components/organisms/PhoneNumberForm";
import { analytics } from "lib/analytics";
import { gql } from "@apollo/client";
import { useCaptureGraphQLError } from "../../../../api";
import { analyticsEvents } from "../../../../constants/analytics-events";
import { useNotificationsQuery } from "../../../utilities/notifications";
import { useCompleteAccountDetailsMutation } from "./AccountDetailsForm.generated";

gql`
	mutation CompleteAccountDetails($form: AccountDetailsInput!) {
		completeAccountDetails(form: $form) {
			id
			hasProvidedAccountDetails
		}
	}
`;

const validationSchema = Yup.object({
	phoneNumber: phoneNumberSchema,
	address: addressSearchFormSchema,
	consent: Yup.boolean().test(
		"is-true",
		"Accept these terms to continue",
		Boolean,
	),
});

const accountDetailsinitialValues = {
	phoneNumber: phoneNumberInitialValues,
	address: addressSearchFormInitialValues,
	consent: false,
};

type Props = {
	onError?: () => void;
	onSuccess?: () => void;
	defaultValues?: Partial<typeof accountDetailsinitialValues>;
};

const AccountDetailsForm = ({ defaultValues, onSuccess, onError }: Props) => {
	const captureMutationError = useCaptureGraphQLError();
	// @ts-ignore
	const [, { refetch: refetchNotifications }] = useNotificationsQuery();

	const [completeAccountDetails] = useCompleteAccountDetailsMutation({
		onCompleted: () => {
			const { event, properties = {} } =
				analyticsEvents.COMPLETE_ACCOUNT_DETAILS_EVENT;
			analytics.track(event, properties);
			/**
			 * After providing account details, we can reasonably assume
			 * there will be new notifications for the user to complete.
			 */
			refetchNotifications();
			onSuccess?.();
		},
		onError: (error) => {
			captureMutationError(error);
			onError?.();
		},
	});

	const initialValues = {
		...accountDetailsinitialValues,
		...defaultValues,
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values) => {
				await completeAccountDetails({
					variables: {
						form: {
							phoneNumber: values.phoneNumber?.phoneNumber,
							callingCode: values.phoneNumber?.callingCode,
							consent: values.consent,
							addressPlaceId: values.address?.placeId,
							addressRawSearch: values.address?.rawSearch,
							addressLine1: values.address?.addressLine1 || "",
							addressCity: values.address?.city || "",
							addressSuburb: values.address?.suburb || "",
							addressPostCode: values.address?.postCode || "",
							addressCountry: values.address?.country || "",
						},
					},
				});
			}}
		>
			{({
				dirty,
				isSubmitting,
				setFieldValue,
				setFieldTouched,
				values,
				errors,
				touched,
			}) => (
				<Form>
					<div className="grid gap-1 mb-3">
						<AddressSearchForm
							label="Current residential address"
							groupKey="address"
						/>
						<PhoneNumberForm label="Phone" groupKey="phoneNumber" />
						<Checkbox
							id="consent"
							name="consent"
							checked={values.consent}
							onBlur={() => setFieldTouched("consent", true)}
							hasError={Boolean(touched.consent && errors.consent)}
							error={errors.consent}
							onChange={(value) => setFieldValue("consent", value)}
							label={
								<div className="mt-1">
									I consent to my information being passed to and checked with
									the document issuer and any authorised third parties for the
									purpose of verifying my identity and address.
								</div>
							}
						/>
					</div>
					<Button
						fullWidth
						isDisabled={!dirty}
						isLoading={isSubmitting}
						aria-label="Submit completed account details"
						data-testid="submit-account-details-btn"
						type="submit"
					>
						Complete now
					</Button>
				</Form>
			)}
		</Formik>
	);
};

export default AccountDetailsForm;
